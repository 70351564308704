import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Snackbars, { EAlertType } from '../common/Snackbars';
import ReactHtmlParser from 'react-html-parser';

const API_URL = process.env.REACT_APP_API_URL;
const REPORT_URL = process.env.REACT_APP_REPORT_URL;
const EventReminders = () => {
  const axiosConfig = {
    headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
  };
  const { clientId } = useParams() as { clientId: string | number };

  const [remindersList, setRemindersList] = React.useState<Record<string, any>[]>([]);
  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });
  const [eventDetail, setEventDetail] = React.useState({
    message: '',
    EventDetails: {
      _id: '',
      id: '',
      channelId: '',
      title: '',
      writeUp: '',
      featuredPresenters: '',
      displayStartTime: '',
      displayEndTime: '',
      videoURL: '',
      startTime: '',
      duration: '',
      category: '',
      bookletURL: '',
      donationFlag: '',
      thumbNail: '',
      chatFlag: '',
      cardFeature: '',
      synopsis: '',
      isFreeEvent: '',
    },
  });
  useEffect(() => {
    axios
      .get(`${API_URL}/event_trigger?tenant=${clientId}`)
      .then((response) => {
        setRemindersList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${REPORT_URL}/upcoming/findUpcomingEvent`, {
        client: clientId,
      })
      .then((response) => {
        setEventDetail(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [alertOptions, clientId]);

  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };

  const trigger = () => {
    let url = `${REPORT_URL}/upcoming/upcomingEventIntimation`;
    const data: any = {
      client: clientId,
      event: eventDetail.EventDetails,
    };

    axios
      .post(url, data, axiosConfig)
      .then((response) => {
        setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
        let details = {
          id: eventDetail.EventDetails._id,
          session: eventDetail.EventDetails.id,
          triggeredtime: new Date().toUTCString(),
          users: response.data.response,
          updatedtime: '',
          showOccurence: eventDetail.EventDetails.startTime,
          title: eventDetail.EventDetails.title,
        };
        let url = `${API_URL}/event_trigger/`;
        const data: Record<string, any> = details;
        data['tenant'] = clientId;
        axios
          .post(url, data, axiosConfig)
          .then((response) => {
            setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
          })
          .catch((error) => {
            setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
            console.log(error);
          });
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
  };

  return (
    <div className="container-wrapper">
      <div className="watch-party-form-container">
        {alertOptions.isOpen && (
          <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
        )}

        {eventDetail.message !== '' ? (
          <div className="">
            <div>
              <p className="event-heading">We are happy to announce there is one upcoming event</p>
              <div style={{ display: 'flex', fontSize: '16px' }}>
                <p>Event name : </p>
                {ReactHtmlParser(eventDetail.EventDetails?.title)}
              </div>
              <p className="event-trigger-text">Date : {new Date(eventDetail?.EventDetails?.startTime).toString()}</p>
              <p className="event-trigger-text">If you want to sent reminders for all of our users kindly trigger</p>
            </div>
            <button className="block" onClick={() => trigger()}>
              Trigger
            </button>
          </div>
        ) : (
          <div className="no-event">
            <p className="event-heading">There is no upcoming event !!!</p>
          </div>
        )}

        <div className="event-listing">
          <div>
            <label>Id</label>
          </div>
          <div>
            <label>Show Name</label>
          </div>
          <div>
            <label>Show Occurence</label>
          </div>
          <div>
            <label>Event Triggered Time</label>
          </div>
          <div>
            <label>Users Count</label>
          </div>
          <div>
            <label>User List</label>
          </div>
        </div>
        <div className="container-underline"></div>
        {remindersList.length > 0 &&
          remindersList.map((row, index) => (
            <div key={index}>
              <div className="event-listing reminder-listing-item">
                <div>
                  <label>{row?._id}</label>
                </div>
                <div>
                  <label>{ReactHtmlParser(eventDetail.EventDetails?.title)}</label>
                </div>
                <div>
                  <label>{new Date(row?.showOccurence).toString()}</label>
                </div>
                <div>
                  <label>{new Date(row?.triggeredtime).toString()}</label>
                </div>
                <div>
                  <label>{row?.users.length}</label>
                </div>
                <div>
                  <label>{'-'}</label>
                </div>
              </div>
              <div className="container-underline"></div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EventReminders;
