import React, { useEffect } from 'react';
import { Button, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useParams } from 'react-router-dom';
import DeleteAction from './DeleteAction';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import Snackbars, { EAlertType } from '../common/Snackbars';

const reportConfig = {
  accountsReport: {
    url: '/accountsReport?filename=accountsReport',
    title: 'Accounts Report',
  },
  settlementReport: {
    title: 'Settlement Report',
    url: '/settlementReport?filename=settlementReport',
  },
  salesSummaryReport: {
    title: 'Sales Summary Report',
    url: '/salesSummaryReport?filename=salesSummaryReport',
  },
  salesByPriceReport: {
    title: 'Sales By Price Report',
    url: '/salesByPriceReport?filename=salesByPriceReport',
  },
  financialAnalysisReport: {
    title: 'Financial Analysis Report',
    url: '/financialAnalysisReport?filename=financialAnalysisReport',
  },
  salesByPatronReport: {
    title: 'Sales By Patron Report',
    url: '/salesByPatronReport?filename=salesByPatronReport',
  },
  inventoryReport: {
    title: 'Inventory Report',
    url: '/inventoryReport?filename=inventoryReport',
  },
  returnsReport: {
    title: 'Returns Report',
    url: '/returnsReport?filename=returnsReport',
  },
  journalcsv: {
    title: 'Journal CSV',
    url: '/journalcsv',
  },
  invoicecsv: {
    title: 'Invoice CSV',
    url: '/invoicecsv',
  },
};

const API_URL = process.env.REACT_APP_API_URL;

const ReportLayout = () => {
  const axiosConfig = {
    headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
  };
  const { clientId, reportId } = useParams() as { clientId: string | number; reportId: string | number };
  // Local variables
  let date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  const currentFromDate = new Date(date.valueOf() - 30 * 24 * 60 * 60 * 1000).toISOString().split('.')[0];
  const currentDate = date.toISOString().split('.')[0];
  // Local state
  const [fromDate, setFromDate] = React.useState<string>(currentFromDate);
  const [toDate, setToDate] = React.useState<string>(currentDate);
  const [parsedFromDate, setParsedFromDate] = React.useState<string>(currentFromDate);
  const [parsedToDate, setParsedToDate] = React.useState<string>(currentDate);
  const [type, setType] = React.useState<string>('pdf');

  const { url, title } = reportConfig[reportId];

  useEffect(() => {
    fetchRecord(true, false);
  }, [clientId, reportId, fromDate, toDate]);

  let FormDetails = [{ title: '', count: '', amount: '', remarks: '', reference_id: '' }];
  const [OverrideFormDetails, setOverrideFormDetails] = React.useState(FormDetails);
  const [editData, setEditData] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [alertOptions, setAlertOptions] = React.useState({ isOpen: false, message: '', type: EAlertType.INFO });

  const addOverrideRow = () => {
    setOverrideFormDetails([...OverrideFormDetails, FormDetails[0]]);
  };

  const OnChangeOverrideForm = (e, index) => {
    let newFormDetails = [...OverrideFormDetails];
    newFormDetails[index][e.target.name] = e.target.value;
    setOverrideFormDetails(newFormDetails);
  };

  const onDeleteOverrideRow = (index) => {
    let newFormDetails = [...OverrideFormDetails];
    newFormDetails.splice(index, 1);
    setOverrideFormDetails(newFormDetails);
  };

  const onOverrideSaveAndEdit = () => {
    let newData = {
      startDate: parsedFromDate,
      endDate: parsedToDate,
      reportType: reportId,
      data: JSON.stringify(OverrideFormDetails),
      clientId: clientId,
      tenant: 'ADMIN_SETTINGS',
      collection_id: '6217a08fcfc10700130e9275',
      collection: 'reportOverrides',
      isPublished: true,
    };
    newData['preview'] = cloneDeep(newData);
    let url = `${API_URL}/contents/`;
    let method = 'post';
    let message = 'Changes saved!';
    let type = 'saved';
    if (isEdit) {
      newData['_id'] = editData['_id'];
      newData['preview']['_id'] = editData['_id'];
      url = `${API_URL}/contents/${editData['_id']}`;
      method = 'put';
      message = 'Changes updated!';
      type = 'update';
    }

    axios[method](url, newData, axiosConfig)
      .then((response) => {
        setAlertOptions({ isOpen: true, message: message, type: EAlertType.SUCCESS });
        fetchRecord(true, false);
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: `Failed to ${type}! Try again`, type: EAlertType.ERROR });
      });
  };

  const fetchRecord = (isEditClicked, isEditClickedAgain) => {
    fetch(`${API_URL}/contents?collection=reportOverrides&tenant=ADMIN_SETTINGS`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const requiredRecord = data.data.find((item) => {
          return (
            item.clientId == clientId &&
            item.reportType == reportId &&
            new Date(item.startDate).toISOString() === parsedFromDate &&
            new Date(item.endDate).toISOString() === parsedToDate
          );
        });

        if (requiredRecord) {
          if (isEditClicked) {
            setIsEdit(true);
            setEditData(requiredRecord);
            setOverrideFormDetails(JSON.parse(requiredRecord.data));
          } else {
            setIsEdit(false);
            setOverrideFormDetails(FormDetails);
          }
        } else {
          setIsEdit(false);
          setOverrideFormDetails(FormDetails);
          isEditClickedAgain && setAlertOptions({ isOpen: true, message: `No Records Exist`, type: EAlertType.ERROR });
        }
      })
      .catch((err) => {});
  };

  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };
  React.useEffect(() => {
    let timezoneFormatted = new Date(fromDate);
    timezoneFormatted.setMinutes(timezoneFormatted.getMinutes() - timezoneFormatted.getTimezoneOffset());
    setParsedFromDate(timezoneFormatted.toISOString());
  }, [fromDate]);

  React.useEffect(() => {
    let timezoneFormatted = new Date(toDate);
    timezoneFormatted.setMinutes(timezoneFormatted.getMinutes() - timezoneFormatted.getTimezoneOffset());
    setParsedToDate(timezoneFormatted.toISOString());
  }, [toDate]);

  return (
    <div className="container-wrapper">
      {alertOptions.isOpen && (
        <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
      )}
      <div className="container-title">
        <h1>{title}</h1>
      </div>
      <div className="container-form-date-picker">
        <div className="container-form-lable">
          <label>Reporting Period: </label>
        </div>
        <div style={{ marginRight: '25px' }}>
          <TextField
            id="datetime-local"
            label="From"
            type="datetime-local"
            defaultValue={fromDate}
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
        </div>
        <div>
          <TextField
            id="datetime-local"
            label="To"
            type="datetime-local"
            defaultValue={toDate}
            style={{ width: '50%' }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
          />
        </div>
      </div>

      {OverrideFormDetails.map((data, index) => {
        return (
          <div className="override-form" key={index}>
            <div className="container-form-lable">
              <label>Manual Overrides: </label>
            </div>
            <div className="override-field-container">
              <div className="override-field-elements">
                <label>Title</label>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  name="title"
                  value={data.title}
                  onChange={(e) => OnChangeOverrideForm(e, index)}
                />
              </div>
              <div className="override-field-elements">
                <label>Count</label>
                <TextField
                  id="outlined-basic"
                  label="Count"
                  variant="outlined"
                  name="count"
                  type="number"
                  value={data.count}
                  onChange={(e) => OnChangeOverrideForm(e, index)}
                />
              </div>
              <div className="override-field-elements">
                <label>Amount</label>
                <TextField
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  name="amount"
                  type="number"
                  value={data.amount}
                  onChange={(e) => OnChangeOverrideForm(e, index)}
                />
              </div>
              <div className="override-field-elements">
                <label>Remarks</label>
                <TextField
                  id="outlined-basic"
                  label="Remarks"
                  variant="outlined"
                  name="remarks"
                  value={data.remarks}
                  onChange={(e) => OnChangeOverrideForm(e, index)}
                />
              </div>
              <div className="override-field-elements">
                <label>Reference Id</label>
                <TextField
                  id="outlined-basic"
                  label="Reference Id"
                  variant="outlined"
                  name="reference_id"
                  value={data.reference_id}
                  onChange={(e) => OnChangeOverrideForm(e, index)}
                />
              </div>
              <div className="override-add-icon">
                {OverrideFormDetails.length === index + 1 && (
                  <span role="button" onClick={() => addOverrideRow()}>
                    <AddCircleIcon color="primary" />
                  </span>
                )}
                {index !== 0 && (
                  <span role="button">
                    <DeleteAction onDeleteClickHandler={() => onDeleteOverrideRow(index)} url={`${index}`} />
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      })}

      <div className="override-form-submit">
        <>
          <div>
            <Button
              variant="contained"
              onClick={() => {
                fetchRecord(true, true);
              }}
            >
              Edit
            </Button>
          </div>
          <div style={{ paddingLeft: '20px' }}>
            <Button variant="contained" color={isEdit ? 'secondary' : 'inherit'} onClick={() => onOverrideSaveAndEdit()}>
              {isEdit ? 'Update' : 'Save'}
            </Button>
          </div>
        </>
      </div>

      <div className="container-form-radio-buttons">
        <div className="container-form-lable">
          <label>Reporting File Type: </label>
        </div>
        <div>
          <RadioGroup row value={type} onChange={(e) => setType(e.target.value)}>
            <FormControlLabel value="pdf" control={<Radio color="default" />} label="PDF" />
            {/* <FormControlLabel value="csv" control={<Radio color="default" />} label="CSV" />
            <FormControlLabel value="xls" control={<Radio color="default" />} label="XLS" /> */}
          </RadioGroup>
        </div>
      </div>
      <div className="container-underline"></div>
      <div className="container-form-submit">
        <Button variant="contained" color="inherit">
          <a
            type="button"
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_NODE_URL}${clientId}/${Date.parse(parsedFromDate)}/${Date.parse(parsedToDate)}/${type}${url}`}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            Generate
          </a>
        </Button>
      </div>
    </div>
  );
};

export default ReportLayout;
