import React, { useEffect, useState } from 'react';
import { withTheme } from '@rjsf/core';
import { Theme as FluentUITheme } from '@rjsf/fluent-ui';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Input } from '@StixNext/mojito-ui';
import FileWidget from './FileWidget';
import moment from 'moment-timezone';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';

import SampleSchema from '../../utils/schema.json';
import '../../styles/editor.css';

const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

function Editor(props) {
  console.log('fine input editor', props, props.value);
  // const Form = withTheme(MaterialUITheme);]
  const [removedImages, setRemovedImages] = useState([{ name: '', value: '' }]);
  Object.keys(props.data.properties).forEach((eachProps) => {
    console.log('props data oobject', props?.data, props?.formData);
    if (props?.data?.properties?.[eachProps]?.format === 'data-url') {
      if (Object.keys(props?.formData)?.length > 0) {
        if (!props.data?.required?.includes(eachProps)) {
          if (props?.formData?.[eachProps] === '' || props?.formData?.[eachProps] === 'undefined') {
            props.formData[eachProps] = 'data:image/gif;base64,';
          } else {
            if (Object.keys(props?.formData)?.length > 0) {
              if (!props?.formData?.[eachProps]?.includes('data:image/gif;base64,')) {
                props.formData[eachProps] = 'data:image/gif;base64,' + props.formData[eachProps];
              }
            }
          }
        } else {
          if (props?.formData?.[eachProps] !== '' || props?.formData?.[eachProps] !== 'undefined') {
            if (!props?.formData?.[eachProps]?.includes('data:image/gif;base64,')) {
              props.formData[eachProps] = 'data:image/gif;base64,' + props.formData[eachProps];
            }
          }
        }
      }
    } else if (Object.keys(props.data).length > 0 && !props.data?.required?.includes(eachProps)) {
      if (!props.data?.required?.includes(eachProps) && props?.data?.properties?.[eachProps]?.format === 'date-time') {
        console.log(
          'new date',
          moment(moment(new Date().toLocaleString('en-SG', { timeZone: 'Asia/Singapore' })).format('YYYY-MM-DD hh:mm a')).toJSON(),
          new Date().toLocaleString('en-SG', { timeZone: 'Asia/Singapore' }),
        );
        props.data.properties[eachProps]['default'] = moment(moment(new Date().toUTCString()).format('YYYY-MM-DD hh:mm a')).toJSON();
      }
      if (props?.data?.properties?.[eachProps]?.type === 'number') {
        props.data.properties[eachProps]['default'] = 0;
      }
    }
  });

  const Form = withTheme(FluentUITheme);
  const schema = props.data || SampleSchema;

  const htmlEditor = {
    'ui:widget': (props) => (
      <div>
        <h2>{props.schema.title ?? props.schema.titleText}</h2>
        <CKEditor
          editor={ClassicEditor}
          data={props.value}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            props.onChange(data);
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
      </div>
    ),
  };

  const FilePreviewWidget = (props) => {
    if (!props.value) {
      return <div>No file uploaded.</div>;
    }
    return (
      <div>
        {/* <h2>{props.schema.title ?? props.schema.titleText} </h2> */}
        <img
          src={props.value.replace('data:image/gif;base64,', '', 'undefined')}
          style={Object.assign({ width: 'auto', height: 'auto' }, props.style)}
        ></img>
      </div>
    );
  };

  function base64MimeType(encoded) {
    let result = '';

    if (typeof encoded !== 'string') {
      return result;
    }

    let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }

  const handleRemoveImg = (data) => {
    console.log('data remoe', data.title, props.formData[data.title], props);
    if (Object.keys(props?.formData)?.length > 0) {
      props.formData[data.title] = 'data:image/gif;base64,';
      // setRemovedImages([...removedImages, { name: data.title, value: props.formData[data.title] }]);
    }
    console.log('data remoe', data.title, props.formData[data.title]);
  };

  const updateFormData = (title, data) => {
    console.log('fine input data remoe', data, props);
    if (Object.keys(props?.formData)?.length > 0) {
      console.log('fine input props formadata inside');
      props.formData[title] = 'data:image/gif;base64,' + data;
    }
    console.log('after update', props);
  };

  const FileInputAndPreviewWidget = (props) => {
    const output: JSX.Element[] = [];
    console.log('fine input preview', props, props.value);
    if (props.value && props.value !== 'data:image/gif;base64,') {
      output.push(<FilePreviewWidget key="preview" {...props} style={{ marginBottom: 10, maxHeight: 200, maxWidth: '100%' }} />);
    }
    console.log('fine input before', props, props.value);
    console.log('fine input', props, props.value);
    output.push(
      <FileWidget
        key="file"
        // id={props.id}
        {...props}
        // value={undefined}
        handleRemoveImg={handleRemoveImg}
        onChange={async (v) => {
          // if (!['image/png', 'image/jpeg', 'image/jpg'].includes(base64MimeType(v))) {
          //   window.alert('Uploaded file must be a png');
          // } else {
          console.log('fine input onchange image', v, props.schema.titleText);
          await updateFormData(props.schema.titleText, v);
          await props.onChange('data:image/gif;base64,' + v);
          // }
        }}
      />,
    );

    return output;
  };

  const input = {
    'ui:widget': (props) => (
      <Input
        id="textbox"
        type="input"
        textarea={false}
        cols="100"
        rows="10"
        disabled={false}
        error={false}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        validationCaption="Enter a valid name"
        placeholder="Name"
      />
    ),
  };

  const inputDate = {
    'ui:widget': (props) => {
      console.log('date event props', props.value, props.schema.title);
      return (
        <div>
          <h2>
            {props.schema.title ?? props.schema.titleText}
            {props.required && <span style={{ color: 'rgb(164, 38, 44)' }}> *</span>}
          </h2>{' '}
          <Datetime
            displayTimeZone={'Asia/Singapore'}
            className="cms-datetime"
            closeOnSelect={true}
            value={props.value ? new Date(props.value) : !props?.required ? new Date() : ''}
            dateFormat={'DD-MM-YYYY'}
            onChange={(event) => {
              console.log(
                'date event',
                new Date(props.value),
                event,
                moment(event),
                moment(event).toJSON(),
                moment(event).toJSON(),
                moment(moment(event).toJSON()),
                moment(moment(new Date().toUTCString()).format('YYYY-MM-DD hh:mm a')).toJSON(),
              );
              props.onChange(moment(event).toJSON());
            }}
          />
        </div>
      );
    },
  };

  const uiSchema = {
    'ui:options': {
      label: true,
    },
  };

  Object.keys(schema.properties).forEach((scheme) => {
    console.log('date event format', schema.properties[scheme].format, props.value);
    if (schema.properties[scheme].type === 'string' && schema.properties[scheme].format === 'string') {
      uiSchema[scheme] = input;
    }
    if (schema.properties[scheme].type === 'string' && schema.properties[scheme].format === 'date-time') {
      uiSchema[scheme] = inputDate;
    }
    if (schema.properties[scheme].customEditor) {
      uiSchema[scheme] = htmlEditor;
    }
    if (schema.properties[scheme].textArea) {
      uiSchema[scheme] = {
        'ui:widget': 'textarea',
      };
    }
  });

  function ObjectFieldTemplate(props) {
    return (
      <div className="form-wrapper">
        <div className="form-title">{props.title}</div>
        <div>{props.description}</div>
        {props.properties.map((element) => {
          return <div style={{ width: '90%' }}>{element.content}</div>;
        })}
      </div>
    );
  }

  const onSubmit = (e: any) => {
    console.log('props formdata', props.formData, e.formData);
    if (Object.keys(props.formData).length > 0) {
      let result = {};
      Object.keys(e.formData).forEach((data) => {
        if (e.formData[data] !== undefined) {
          if (typeof e.formData[data] === 'string') {
            result[data] = e.formData[data].replace('data:image/gif;base64,', '').replace(FILE_UPLOAD_URL, '');
          } else {
            result[data] = e.formData[data];
          }
        }
      });
      console.log('result data', result);
      props.onSubmit(result, true);
    } else {
      let result = {};
      console.log('e formdata', e, e.formData);
      Object.keys(e.formData).forEach((data) => {
        console.log('e formdata new date', e.formData[data], e.schema.properties[data].format, e.schema.properties[data].format === 'date-time');
        if (e.formData[data] !== undefined) {
          console.log('formdata data', e.formData[data]);
          if (typeof e.formData[data] === 'string' && e.schema.properties[data]?.format !== 'date-time') {
            console.log('formdata data type', e.formData[data]);
            result[data] = e.formData[data].replace('data:image/gif;base64,', '').replace(FILE_UPLOAD_URL, '');
          } else {
            console.log('formdata data else', e.formData[data]);
            result[data] = e.formData[data];
          }
        }
      });
      console.log('final result', result);
      props.onSubmit(result, false);
    }
  };

  const onError = (data) => {
    console.log(data, 'error');
  };

  let widgets;

  widgets = { FileWidget: FileInputAndPreviewWidget };
  return (
    <div style={{ margin: '20px' }}>
      <Form
        widgets={widgets}
        schema={schema}
        uiSchema={uiSchema}
        ObjectFieldTemplate={ObjectFieldTemplate}
        formData={props.formData}
        onSubmit={onSubmit}
        onError={onError}
        liveValidate={false}
      ></Form>
    </div>
  );
}

export default Editor;
