import React, { useEffect, useState } from 'react';
import { Button, FormControl, MenuItem, Select, TextField, Checkbox } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import Snackbars, { EAlertType } from '../common/Snackbars';
import DeleteAction from '../common/DeleteAction';
import RoomConfirmation from './RoomConfirmation';
import FuzzySearch from 'react-fuzzy';
import { auditLogger, EAUDIT_TYPE, EAUDIT_COLLEACTION } from '../../utils/Utils';
import { cloneDeep } from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_REPORT_URL = process.env.REACT_APP_REPORT_URL;

interface IRoomDetails {
  name: string;
  id: string;
  product: string;
  videoId: string;
}
export interface IGuestDetails {
  firstName: string;
  lastName: string;
  email: string;
  isSendEmail: boolean;
}
const roomData = {
  name: '',
  id: '',
  product: '',
  videoId: '',
};

const RoomCreation = () => {
  const axiosConfig = {
    headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
  };
  const history = useHistory();
  let { clientId, roomId } = useParams() as { clientId: string | number; roomId: string | number };
  // local state
  const [roomDetails, setRoomDetails] = useState<IRoomDetails>(roomData);
  const [guest, setGuest] = useState<IGuestDetails[]>([]);
  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });
  const [productDetails, setProductDetails] = useState<Record<string, any>[]>([]);
  const [programmeDetails, setProgrammeDetails] = useState<Record<string, any>[]>([]);
  const [open, setOpen] = useState(false);
  const [clonedData, setClonedData] = useState({});
  const [roomList, setRoomList] = useState([]);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [isVideoEdited, setIsVideoEdited] = useState(false);
  const [clonedGuest, setClonedGuest] = useState([]);

  const onCheckboxChangeHandler = (e, index) => {
    let newGuest = [...guest];
    newGuest[index][e.target.name] = e.target.checked;
    setGuest(newGuest);
  };
  // effects
  useEffect(() => {
    axios
      .get(`${API_URL}/contents?collection=products&tenant=${clientId}`, axiosConfig)
      .then((response) => {
        setProductDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
    axios
      .get(`${API_URL}/contents?collection=Programes&tenant=${clientId}`, axiosConfig)
      .then((response) => {
        if (response.data.data.length > 0) {
          setProgrammeDetails(response.data.data);
        } else {
          setProgrammeDetails([]);
        }
      })
      .catch((error) => {
        console.log(error, 'error');
      });
    if (roomId) {
      axios
        .get(`${API_URL}/watch-party?_id=${roomId}&tenant=${clientId}`, axiosConfig)
        .then((response) => {
          if (response.data.data.length > 0) {
            setRoomDetails(response.data.data[0]);
            setGuest(response.data.data[0].guest);
            setClonedData(cloneDeep(response.data.data[0]));
            setClonedGuest(cloneDeep(response.data.data[0].guest));
          } else {
            setRoomDetails(roomData);
            setGuest([]);
          }
        })
        .catch((error) => {
          console.log(error, 'error');
        });
    }
  }, [clientId, roomId]);

  useEffect(() => {
    axios
      .get(`${API_URL}/watch-party?tenant=${clientId}`)
      .then((response) => {
        setRoomList(response.data.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, []);

  // handlers
  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAddClickHandler = () => {
    setGuest([...guest, { firstName: '', lastName: '', email: '', isSendEmail: true }]);
  };

  const invalidateGuests = (index) => {
    const deletedGuestData = clonedGuest[index];
    const programe = programmeDetails.find((programe) => programe.id === roomDetails.videoId);
    if (deletedGuestData) {
      const data = {
        client: clientId,
        invitees: [deletedGuestData],
        roomInfo: {
          roomid: clonedData['id'],
          roomName: roomDetails.name,
          sessionid: programe?.id,
          sessionTitle: programe?.title,
          session_starttime: programe?.startTime,
        },
      };
      axios
        .post(`${REACT_APP_REPORT_URL}/watchparty/invalidateVipInvitation`, data)
        .then((response) => {
          setAlertOptions({ isOpen: true, message: `Guest's invitation invalidated successfully`, type: EAlertType.SUCCESS });
        })
        .catch((error) => {
          setAlertOptions({ isOpen: true, message: `Failed to invalidate Guest's Invitation! Try again`, type: EAlertType.ERROR });
        });
    }
  };

  const onDeleteClickHandler = (index: string) => {
    const newGuest = guest.filter((_, i) => i !== parseInt(index));
    setGuest(newGuest);
    const newClonedGuest = clonedGuest.filter((_, i) => i !== parseInt(index));
    setClonedGuest(newClonedGuest);
    if (roomId) invalidateGuests(index);
  };

  const onUserTextChangeHandler = (index: number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newGuest = [...guest];
    let emailDomain = '';
    if (event.target.name === 'emailText') {
      if (newGuest[index]['email'] && newGuest[index]['email'].split('@')[1]) {
        emailDomain = newGuest[index]['email'].split('@')[1];
      }
      newGuest[index]['email'] = event.target.value;
      if (emailDomain) {
        newGuest[index]['email'] = newGuest[index]['email'] + '@' + emailDomain;
      }
    } else if (event.target.name === 'emailDomain') {
      emailDomain = event.target.value;
      newGuest[index]['email'] = newGuest[index]['email'].split('@')[0];
      newGuest[index]['email'] = newGuest[index]['email'] + '@' + event.target.value;
    } else {
      newGuest[index][event.target.name] = event.target.value;
    }
    setGuest(newGuest);
  };

  const onChangeHandler = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const newRoomDetails = { ...roomDetails };
    newRoomDetails[event.target.name as string] = event.target.value;
    setRoomDetails(newRoomDetails);
  };

  const onCancelClickHandler = () => {
    history.goBack();
  };

  const sendEmail = () => {
    const user = JSON.parse(window.localStorage.getItem('user') as string);
    const product = productDetails.find((product) => product.id === roomDetails.product);
    const programe = programmeDetails.find((programe) => programe.id === roomDetails.videoId);
    const data = {
      client: clientId,
      product: product,
      invitees: guest.filter((item) => item.isSendEmail && item.isSendEmail == true),
      moderator: {
        id: user.jti,
        email: user.email,
        firstname: user.given_name,
        lastname: user.family_name,
      },
      roomInfo: {
        roomid: roomDetails.id,
        roomName: roomDetails.name,
        sessionid: programe?.id,
        sessionTitle: programe?.title,
        session_starttime: programe?.startTime,
      },
    };
    axios
      .post(`${REACT_APP_REPORT_URL}/watchparty/createVipInvitation`, data)
      .then((response) => {
        setAlertOptions({ isOpen: true, message: 'Invitation sent successfully', type: EAlertType.SUCCESS });
        setTimeout(() => {
          onCancelClickHandler();
        }, 500);
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Invitation not sent', type: EAlertType.ERROR });
      });
  };

  const onSubmit = (isEdit: boolean) => {
    setIsSendDisabled(true);
    let url = `${API_URL}/watch-party/`;
    const data: Record<string, any> = roomDetails;
    data['guest'] = guest.map((user) => ({ ...user, isSendEmail: false }));
    data['tenant'] = clientId;

    if (isEdit) {
      axios
        .put(url + data._id, data, axiosConfig)
        .then((response) => {
          setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
          handleClose();
          setIsSendDisabled(false);
          sendEmail();
        })
        .catch((error) => {
          setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
          console.log(error);
          setIsSendDisabled(false);
          sendEmail();
        });
      // AUDIT LOG
      auditLogger(EAUDIT_TYPE.UPDATE, EAUDIT_COLLEACTION.WATCH_PARTY, JSON.stringify(clonedData), JSON.stringify(data));
    } else {
      axios
        .post(url, data, axiosConfig)
        .then((response) => {
          setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
          handleClose();
          setIsSendDisabled(false);
          sendEmail();
        })
        .catch((error) => {
          setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
          console.log(error);
          setIsSendDisabled(false);
        });
      // AUDIT LOG
      auditLogger(EAUDIT_TYPE.CREATE, EAUDIT_COLLEACTION.WATCH_PARTY, JSON.stringify(clonedData), JSON.stringify(data));
    }
  };

  const onSubmitClickHandler = () => {
    const roomNameList: string[] = roomList.filter((item) => item['_id'] !== roomId).map((room) => room['name']);
    const roomIdList: string[] = roomList.filter((item) => item['_id'] !== roomId).map((room) => room['id']);
    const isRoomNameExist: boolean = roomNameList.includes(roomDetails.name.trim());
    const isRoomIdExist: boolean = roomIdList.includes(roomDetails.id.trim());
    const isVideoIdExist = programmeDetails.filter((item) => item['id'] == roomDetails.videoId).length == 0 ? false : true

    const isAnyGuestChecked = guest.find((item) => item.isSendEmail && item.isSendEmail == true);
    const seen = new Set();
    const isEmailRepeated: any = guest.some((item) => seen.size === seen.add(item.email).size);

    // validations
    if (roomDetails.name === '') {
      setAlertOptions({ isOpen: true, message: 'Room name is required', type: EAlertType.ERROR });
      return;
    }
    if (roomDetails.product === '') {
      setAlertOptions({ isOpen: true, message: 'Product is required', type: EAlertType.ERROR });
      return;
    }
    if (roomDetails.videoId === '') {
      setAlertOptions({ isOpen: true, message: 'Video is required', type: EAlertType.ERROR });
      return;
    }
    if (!isVideoIdExist) {
      setAlertOptions({ isOpen: true, message: 'Please select a valid video', type: EAlertType.ERROR });
      return;
    }
    if (roomDetails.id === '') {
      setAlertOptions({ isOpen: true, message: 'Room id is required', type: EAlertType.ERROR });
      return;
    }
    if (isRoomNameExist) {
      setAlertOptions({ isOpen: true, message: 'Room name already exists', type: EAlertType.ERROR });
      return;
    }
    if (isRoomIdExist) {
      setAlertOptions({ isOpen: true, message: 'Room id already exists', type: EAlertType.ERROR });
      return;
    }
    if (isEmailRepeated) {
      setAlertOptions({ isOpen: true, message: 'Email id already used. Please use different mail id', type: EAlertType.ERROR });
      return;
    }
    if (!isAnyGuestChecked) {
      setAlertOptions({ isOpen: true, message: 'Please select any one of the guests to send invitation', type: EAlertType.ERROR });
      return;
    }
    let isValid = true;
    if (guest.length > 0) {
      guest.map((user) => {
        if (user.firstName === '') {
          isValid = false;
          setAlertOptions({ isOpen: true, message: 'First name is required', type: EAlertType.ERROR });
          return;
        }
        if (user.lastName === '') {
          isValid = false;
          setAlertOptions({ isOpen: true, message: 'Last name is required', type: EAlertType.ERROR });
          return;
        }
        if (user.email === '') {
          isValid = false;
          setAlertOptions({ isOpen: true, message: 'Email is required', type: EAlertType.ERROR });
          return;
        }
      });
    }
    if (isValid) setOpen(true);
  };

  return (
    <div className="container-wrapper">
      {alertOptions.isOpen && (
        <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
      )}
      <div className="container-title">
        <h1>{(roomId ? '' : 'New ') + 'Private Room'}</h1>
      </div>
      <div className="watch-party-form-container">
        <div className="watch-party-form">
          <div className="watch-party-form-element">
            <label>
              Private Room Name <sup>*</sup>
            </label>
            <TextField
              id="outlined-basic"
              label="Private Room Name"
              variant="outlined"
              name="name"
              value={roomDetails?.name}
              onChange={onChangeHandler}
            />
          </div>
          <div className="watch-party-form-element">
            <label>
              Private Room Id <sup>*</sup>
            </label>
            <TextField id="outlined-basic" label="Private Room Id" variant="outlined" name="id" value={roomDetails?.id} onChange={onChangeHandler} />
          </div>
        </div>
        <div className="watch-party-form">
          <div className="watch-party-form-element">
            <label>
              Product <sup>*</sup>
            </label>
            <FormControl variant="outlined">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="product"
                value={roomDetails?.product}
                onChange={onChangeHandler}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productDetails.length > 0 &&
                  productDetails.map((product) => {
                    if ((product.isFreeEvent && product.isFreeEvent == true) || product.price == 0) {
                      return (
                        <MenuItem key={product.id} value={product.id}>
                          {product.id}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="watch-party-form-element">
            <label>
              Video URL ID <sup>*</sup>
            </label>
            {programmeDetails.length > 0 && (((roomDetails.videoId !== '' || isVideoEdited) && roomId) || !roomId) && (
              <FuzzySearch
                list={programmeDetails}
                keys={['id']}
                shouldShowDropdownAtStart={true}
                width={'100%'}
                inputProps={{ defaultValue: roomDetails.videoId,onChange : (e)=> {
                  const newRoomDetails = { ...roomDetails };
                  newRoomDetails['videoId'] = e.target.value;
                  setRoomDetails(newRoomDetails);
                  setIsVideoEdited(true)
                  }}}
                keyForDisplayName={'id'}
                placeholder={'Search...'}
                onSelect={(newSelectedItem) => {
                  const newRoomDetails = { ...roomDetails };
                  newRoomDetails['videoId'] = newSelectedItem.id;
                  setRoomDetails(newRoomDetails);
                }}
              />
            )}
          </div>
        </div>
        <div className="watch-party-add-user">
          <span role="button" onClick={onAddClickHandler}>
            <AddCircleIcon color="primary" />
            <span>Add Guest</span>
          </span>
        </div>
        {guest.map((guest, index) => (
          <div className="watch-party-user-details" key={index}>
            <div className="watch-party-form-element">
              <Checkbox
                className="add_guest_check_box"
                name="isSendEmail"
                checked={guest.isSendEmail}
                onChange={(e) => onCheckboxChangeHandler(e, index)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            <div className="watch-party-form-element">
              <label>
                First Name <sup>*</sup>
              </label>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                name="firstName"
                value={guest.firstName}
                onChange={(e) => onUserTextChangeHandler(index, e)}
              />
            </div>
            <div className="watch-party-form-element">
              <label>
                Last Name <sup>*</sup>
              </label>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                name="lastName"
                value={guest.lastName}
                onChange={(e) => onUserTextChangeHandler(index, e)}
              />
            </div>
            <div className="watch-party-form-element">
              <label>
                Email Address <sup>*</sup>
              </label>
              <div className="watch-party-user-email action-button">
                <TextField
                  id="outlined-basic"
                  label="e.g.joe"
                  variant="outlined"
                  name="emailText"
                  onChange={(e) => onUserTextChangeHandler(index, e)}
                  value={guest.email.split('@')[0]}
                />
                <label>@</label>
                <TextField
                  id="outlined-basic"
                  label="e.g.yahoo.com"
                  variant="outlined"
                  name="emailDomain"
                  onChange={(e) => onUserTextChangeHandler(index, e)}
                  value={guest.email.split('@')[1]}
                />
                <DeleteAction onDeleteClickHandler={onDeleteClickHandler} url={`${index}`} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="container-underline"></div>
      <div className="container-form-submit">
        <>
          <div>
            <Button variant="outlined" onClick={() => onCancelClickHandler()}>
              Cancel
            </Button>
          </div>
          <div style={{ paddingLeft: '20px' }}>
            <Button variant="contained" color="inherit" onClick={onSubmitClickHandler}>
              {roomId ? 'Update' : 'Create'}
            </Button>
          </div>
        </>
      </div>
      <RoomConfirmation
        open={open}
        guests={guest}
        isEdit={roomId ? true : false}
        isSendDisabled={isSendDisabled}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default RoomCreation;
