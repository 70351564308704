import React, { Component, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { dataURItoBlob } from './utils';
import axios from 'axios';
import { values } from 'lodash';

const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

function addNameToDataURL(dataURL, name) {
  console.log('add name'.dataURL, name);
  return dataURL.replace(';base64', `;name=${encodeURIComponent(name)};base64`);
}

const processFile = async (file) => {
  const { name, size, type } = file;
  let formData = new FormData();
  console.log('fine input process files', file);
  formData.append('multipartFile', file);
  formData.append('tenant', 'SISTIC');
  // uploading file
  let uploadedUrl = '';
  await axios
    .post(FILE_UPLOAD_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      console.log('process files upload', response);
      uploadedUrl = response.data;
    })
    .catch((error) => {
      console.log('process files error', error);
      console.log(error);
    });
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      console.log('promise', event, name);
      resolve({
        dataURL: addNameToDataURL(event.target.result, name),
        uploadedUrl,
        name,
        size,
        type,
      });
    };
    reader.readAsDataURL(file);
  });
};

function processFiles(files) {
  console.log('fine input process files', files);
  return Promise.all([].map.call(files, processFile));
}

function FilesInfo(props) {
  const { filesInfo } = props;
  if (filesInfo.length === 0) {
    return null;
  }
  console.log('fine input files info', props);
  return (
    <ul className="file-info">
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type, uploadedUrl } = fileInfo;
        return (
          <li key={key}>
            <strong>{uploadedUrl}</strong> ({type}, {size} bytes)
          </li>
        );
      })}
    </ul>
  );
}

function extractFileInfo(dataURLs) {
  console.log('extract file info', dataURLs);
  return dataURLs
    .filter((dataURL) => {
      console.log(
        'extract file info typeof dataurl',
        typeof dataURL,
        dataURL,
        typeof dataURL !== 'undefined',
        dataURL === 'data:image/gif;base64',
        typeof dataURL !== 'undefined' && dataURL === 'data:image/gif;base64',
      );
      return typeof dataURL !== 'undefined' && dataURL === 'data:image/gif;base64';
    })
    .map((dataURL) => {
      console.log('extract file info daa url', dataURL);
      const { blob, name } = dataURItoBlob(dataURL);
      return {
        name: name,
        size: blob.size,
        type: blob.type,
      };
    });
}

function FileWidget(props) {
  const { value } = props;

  const propsvalues = Array.isArray(value) ? value : [value];
  const [values, setValues] = useState(propsvalues);
  const [filesInfo, setFilesInfo] = useState(extractFileInfo(propsvalues));
  const myRef = useRef();
  console.log('state values', props);
  useEffect(async () => {
    console.log('fine input props value change', props.value, values, filesInfo);
    if (!props.value) {
      await props.onChange('');
    }
  }, [props]);

  const onChange = (event) => {
    const { multiple, onChange } = props;
    console.log('onchange event', event, props);
    processFiles(event.target.files).then(async (filesInfo) => {
      const state = {
        values: filesInfo.map((fileInfo) => fileInfo.dataURL),
        filesInfo,
      };
      const uploadedUrl = filesInfo.map((fileInfo) => fileInfo.uploadedUrl);
      console.log('onchange upload url', uploadedUrl);
      await setValues(filesInfo.map((fileInfo) => fileInfo.dataURL));
      await setFilesInfo(filesInfo);
      if (multiple) {
        onChange(uploadedUrl);
      } else {
        onChange(uploadedUrl[0]);
      }
    });
  };

  const { multiple, id, readonly, disabled, autofocus, options } = props;
  return (
    <div>
      <h2>{props.schema.title ?? props.schema.titleText}</h2>
      <p>
        <input
          ref={myRef}
          id={id}
          type="file"
          disabled={readonly || disabled}
          onChange={onChange}
          defaultValue=""
          autoFocus={autofocus}
          multiple={multiple}
          accept={options.accept}
        />
      </p>
      <FilesInfo filesInfo={filesInfo} />
      <div
        key={props.schema.titleText}
        id={id}
        style={{
          cursor: 'pointer',
          border: '2px solid black',
          borderRadius: '2px',
          width: '30%',
          marginTop: '2px',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
        onClick={(e) => {
          console.log('e', e, props.schema.titleText);
          props.handleRemoveImg({ title: props.schema.titleText });
          props.onChange('');
          // this.handleRemove(e);
        }}
      >
        Remove Image
      </div>
    </div>
  );
}

FileWidget.defaultProps = {
  autofocus: false,
};

if (process.env.NODE_ENV !== 'production') {
  FileWidget.propTypes = {
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    autofocus: PropTypes.bool,
  };
}

export default FileWidget;
