import React, { useState, useEffect } from 'react';

import { ITableProps, kaReducer, Table } from 'ka-table';
import { DataType, FilteringMode, SortingMode, SortDirection } from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';
import ApiService from '../../services/ApiService';
import { CSVLink } from 'react-csv';
import { MonetizationOnRounded } from '@material-ui/icons';
import RefundAction from './RefundAction';
import ResendEmail from './ResendEmail';
import UpdateEmail from './UpdateEmail';
import ShowPaymentMethod from './ShowPaymentMethod';
import { Download } from 'react-feather';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import * as XLSX from 'xlsx';

const OPTION_KEY = 'sales-list-stores';

const columns = [
  {
    key: 'email',
    title: 'User Id',
    style: { width: 200 },
    dataType: DataType.String,
  },
  {
    key: 'firstname',
    title: 'First Name',
    style: { width: 200 },
    dataType: DataType.String,
  },
  {
    key: 'lastname',
    title: 'Last Name',
    style: { width: 200 },
    dataType: DataType.String,
  },
  {
    key: 'created_date',
    title: 'Pass Start Date',
    style: { width: 200 },
    dataType: DataType.Date,
    sortDirection: SortDirection.Descend,
  },
  {
    key: 'expiryDate',
    title: 'Expiry Date',
    style: { width: 200 },
    dataType: DataType.Date,
    sortDirection: SortDirection.Descend,
  },
  {
    key: 'passValidity',
    title: 'Pass Validity',
    style: { width: 200 },
    dataType: DataType.String,
  },
  {
    key: 'product_id',
    title: 'Product',
    style: { width: 225 },
    dataType: DataType.String,
  },
  {
    key: 'payment_amount',
    title: '$Paid',
    style: { width: 100 },
    dataType: DataType.Number,
  },
  {
    key: 'created_date',
    title: 'Transacted at',
    style: { width: 200 },
    dataType: DataType.Date,
    sortDirection: SortDirection.Descend,
  },
  { key: 'show_card_info', style: { width: 90 } },
  {
    key: 'creditcard',
    title: 'Card Details',
    style: { width: 150 },
    dataType: DataType.Object,
  },
  {
    key: 'id',
    title: 'Txn #',
    style: { width: 100 },
    dataType: DataType.String,
  },
  {
    key: 'payment_reference',
    title: 'Reference #',
    style: { width: 100 },
    dataType: DataType.String,
  },
  // {
  //   key: 'refundid',
  //   title: 'Refund Txn #',
  //   style: { width: 100 },
  //   dataType: DataType.String,
  // },
  { key: 'command_refund', style: { width: 100 } },
  { key: 'command_resend_email', style: { width: 150 } },
  { key: 'command_update_email', style: { width: 150 } },
];

const defaultOption: ITableProps = {
  columns,
  //editingMode: EditingMode.Cell,
  rowKeyField: 'id',
  sortingMode: SortingMode.Single,
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 10,
  },
  filteringMode: FilteringMode.FilterRow,
  format: ({ column, value }) => {
    switch (column.dataType) {
      case DataType.Date:
        return value && value.toLocaleString('en-SG');
      case DataType.Number:
        return value && value.toFixed(2);
      default:
        break;
    }
    switch (column.key) {
      case 'user_id':
        let emailOrId = value.split(':')[2];
        if (emailOrId === undefined) {
          emailOrId = value;
        }
        return emailOrId;
      case 'creditcard':
        if (!!value) {
          const paymentInfo = JSON.parse(value);
          return paymentInfo?.maskedNumber;
        }
        break;
      case 'shippingaddress':
        if (!!value) {
          const addressInfo = value;
          return addressInfo;
        }
        break;
      case 'product_id':
        try {
          if (JSON.parse(value)) {
            return JSON.parse(value).id;
          }
        } catch {
          return value;
        }
        break;
      default:
        break;
    }
  },
};

const tablePropsInit: ITableProps = {
  ...defaultOption,
  ...JSON.parse(localStorage.getItem(OPTION_KEY) || '0'),
};

const RefundCell = ({ rowData }) => {
  let { clientId } = useParams() as { clientId: string | number };
  return (
    <RefundAction
      iscard={rowData.creditcard !== undefined}
      currentStatus={rowData.payment_status}
      txnToRefund={rowData.id}
      clientId={clientId}
      refundId={rowData.refundid}
    />
  );
};

const ResendEmailCell = ({ rowData }) => {
  let { clientId } = useParams() as { clientId: string | number };
  return <ResendEmail clientId={clientId} transaction={rowData} />;
};

const UpdateEmailCell = ({ rowData }) => {
  let { clientId } = useParams() as { clientId: string | number };
  return <UpdateEmail clientId={clientId} transaction={rowData} />;
};

const PaymentMethodCell = ({ rowData }) => {
  if (!!rowData.creditcard) {
    const paymentInfo = JSON.parse(rowData.creditcard);
    return <ShowPaymentMethod info={paymentInfo?.maskedNumber} img={paymentInfo?.imageUrl} />;
  } else {
    return (
      <div className="pl-3 xl:font-black">
        <MonetizationOnRounded className="xl" />
      </div>
    );
  }
};

const PassValidity = ({ rowData }) => {
  var currentDate = moment(new Date(moment.utc().format()));
  var passExpiryDate = moment(new Date(moment.utc(rowData.product.expiresOn).format()));
  let validationStatus = '';
  if (passExpiryDate.diff(currentDate, 'days') > 0 && passExpiryDate.diff(currentDate, 'days') <= 30) {
    validationStatus = 'Expiring';
  } else if (passExpiryDate.diff(currentDate, 'days') > 30) {
    validationStatus = 'Valid';
  } else {
    validationStatus = 'Expired';
  }

  return <div>{validationStatus}</div>;
};

const PassStartDate = ({ rowData }) => {
  console.log('pass validity', rowData.created_date);
  return <div>{'Valid'}</div>;
};

const PassExpiry = ({ rowData }) => {
  console.log('pass validity', rowData);
  return <div>{moment(new Date(rowData.product.expiresOn)).tz('Asia/Singapore').format('DD/MM/YYYY, hh:mm:ss a')}</div>;
};

const downloadExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, fileName);
};

const Sales: React.FC = () => {
  const [transactions, setTransactions] = React.useState([]);
  const [convertTransactions, setConvertTransactions] = React.useState([]);
  let { clientId } = useParams() as { clientId: string | number };
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  useEffect(() => {
    ApiService.loadSalesData({ clientId: clientId }).then((data) => {
      data.transaction.forEach((row) => {
        if (row.shippingaddress) {
          let shippingdata = JSON.parse(row.shippingaddress);
          shippingdata.line2 = shippingdata && typeof shippingdata.line2 !== 'undefined' ? shippingdata.line2 : '';
          row.shippingaddress =
            row.id +
            ',' +
            shippingdata.recipientName +
            ',' +
            shippingdata.countryCode +
            ',' +
            shippingdata.state +
            ',' +
            shippingdata.city +
            ',' +
            shippingdata.line1 +
            ',' +
            shippingdata.line2 +
            ',' +
            shippingdata.postalCode;
        }
      });
      setTransactions(data.transaction);
      setConvertTransactions(data.transaction);
    });
  }, [clientId]);

  useEffect(() => {
    if (Array.isArray(convertTransactions) && convertTransactions.length > 0 && Object.keys(convertTransactions[0]).length > 0) {
      let trans: any = convertTransactions;
      trans.map((item: any) => {
        Object.keys(item).map((it) => {
          if (typeof item[it] === 'object') {
            item[it] = JSON.stringify(item[it]);
          }
        });
        return item;
      });
      setConvertTransactions(trans);
    }
  }, [convertTransactions]);

  const dispatch: DispatchFunc = (action) => {
    changeTableProps((prevState: ITableProps) => {
      const newState = kaReducer(prevState, action);
      const { data, ...settingsWithoutData } = newState;
      localStorage.setItem(OPTION_KEY, JSON.stringify(settingsWithoutData));
      return newState;
    });
  };
  tablePropsInit.data = transactions;
  return (
    <>
      <Table
        {...tableProps}
        childComponents={{
          dataRow: {
            elementAttributes: ({ rowData }) => ({
              style: {
                backgroundColor:
                  (!rowData.refundid || rowData.refundid === 'NA') && rowData.payment_status !== 'voided'
                    ? 'rgba(0, 255, 0, 0.05)'
                    : 'rgba(255, 0, 0, 0.1)',
              },
              title: `${rowData.id}: ${rowData.payment_status}`,
            }),
          },
          cellText: {
            content: (props) => {
              switch (props.column.key) {
                case 'command_refund':
                  return <RefundCell {...props} />;
                case 'command_resend_email':
                  return <ResendEmailCell {...props} />;
                case 'command_update_email':
                  return <UpdateEmailCell {...props} />;
                case 'show_card_info':
                  return <PaymentMethodCell {...props} />;
                case 'passStartDate':
                  return <PassStartDate {...props} />;
                case 'expiryDate':
                  return <PassExpiry {...props} />;
                case 'passValidity':
                  return <PassValidity {...props} />;
                default:
                  break;
              }
            },
          },
        }}
        dispatch={dispatch}
      />
      <div className="download_links">
        <CSVLink data={transactions} filename={clientId + '-sales-data.csv'} enclosingCharacter={''} separator={';'}>
          <Button variant="contained" color="inherit" type="button">
            Export To Csv
          </Button>
        </CSVLink>
        {convertTransactions.length > 0 && (
          <Button
            variant="contained"
            color="inherit"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              downloadExcel(convertTransactions, clientId + '-sales-data.xlsx');
            }}
          >
            Export To Excel
          </Button>
        )}
      </div>
    </>
  );
};

export default Sales;
