import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { BaseStyles, theme } from '@StixNext/mojito-ui';
import Header from './components/header/Header';
import './App.css';
import ContentBuilder from './components/contents/ContentBuilder';
import FormBuilder from './components/forms/FormBuilder';
import keycloak from '../src/utils/keycloak';
import { PrivateRoute } from './utils/PrivateRoute';
import OfferCodes from './components/reports/OfferCodes';
import Sales from './components/reports/Sales';
import { Redirect } from 'react-router-dom';
import config from './utils/config';
import { getAuthorizedClient } from './utils/AuthorizedFunction';
import axios from 'axios';
import FinanceReconcile from './components/reports/FinanceReconcile';
import ReportLayout from './components/common/ReportLayout';
import RoomCreation from './components/watchParty/RoomCreation';
import RoomListing from './components/watchParty/RoomListing';
import ProductMapper from './components/contents/ProductMapper';
import OfferCodeGeneration from './components/reports/OfferCodeGeneration';
import EventReminders from './components/reports/EventReminders';

const API_URL = process.env.REACT_APP_API_URL;

function App() {
  // state
  const [authenticated, setAuthenticated] = useState<unknown>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [clients, setClients] = useState<[{ value: string; label: string }]>([{ value: 'TESTCLIENT', label: 'TESTCLIENT' }]);

  const resource = config.resource;
  const keycloakResource = keycloak;

  useEffect(() => {
    // get client list
    axios
      .get(`${API_URL}/contents?tenant=ADMIN_SETTINGS&collection=Clients`)
      .then((response) => {
        if (response.data.data.length > 0) setClients(response?.data?.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
    // keycloak auth logic
    keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
      })
      .then((authenticated: unknown) => {
        setAuthenticated(authenticated);
        setRoles(keycloakResource.resourceAccess[resource].roles);
        window.localStorage.setItem('token', keycloakResource.token);
        window.localStorage.setItem('user', JSON.stringify(keycloakResource.tokenParsed));
    
        keycloak.onTokenExpired = function () {
          keycloak
            .updateToken(5)
            .then(function (refreshed: unknown) {
              if (refreshed) {
                console.log('Token was successfully refreshed', keycloakResource.token);
                window.localStorage.setItem('token', keycloakResource.token);
              } else {
                console.log(
                  'Token is still valid ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds',
                );
              }
            })
            .catch(function () {
              console.log('Failed to refresh the token, or the session has expired');
            });
        };
      });
  }, []);

  if (authenticated && roles.length > 0 && clients.length > 0) {
    const clientLists = getAuthorizedClient(roles, clients);
    if (clientLists.length > 0) {
      return (
        <ThemeProvider theme={theme.mojito}>
          <BaseStyles />
          <BrowserRouter>
            <Header roles={roles} clientsDetails={clients} />
            <Switch>
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN', 'DCH_CS', 'DCH_FINANCE', 'DCH_CONTENT_ADMIN']}
                path="/contents/:tenantId"
                component={ContentBuilder}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN', 'DCH_CS', 'DCH_FINANCE']}
                path="/offerCodes/:clientId"
                component={OfferCodes}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN', 'DCH_CS', 'DCH_FINANCE']}
                path="/sales/:clientId"
                component={Sales}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN', 'DCH_FINANCE']}
                path="/financereconcile/:clientId"
                component={FinanceReconcile}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN', 'DCH_FINANCE', 'DCH_CONTENT_ADMIN']}
                path="/reportlayout/:reportId/:clientId"
                component={ReportLayout}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN', 'DCH_FINANCE']}
                path="/listRoom/:clientId"
                component={RoomListing}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN', 'DCH_FINANCE']}
                path="/createRoom/:clientId/:roomId?"
                component={RoomCreation}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN', 'DCH_FINANCE']}
                path="/productMapping/:clientId/:productId?"
                component={ProductMapper}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN']}
                path="/GenerateOfferCode/:clientId"
                component={OfferCodeGeneration}
              />
              <PrivateRoute
                roles={roles}
                restrictedTo={['DCH_SUPER_ADMIN', 'DCH_ADMIN']}
                path="/eventReminders/:clientId"
                component={EventReminders}
              />
              <PrivateRoute roles={roles} restrictedTo={['DCH_SUPER_ADMIN']} path="/:tenantId" component={FormBuilder} />
              <Route
                render={() => {
                  if (roles.includes('DCH_SUPER_ADMIN')) return <Redirect to={'/' + clientLists[0].value} />;
                  else if (roles.includes('DCH_CS')) return <Redirect to={'/offerCodes/' + clientLists[0].value} />;
                  else if (roles.includes('DCH_FINANCE')) return <Redirect to={'/financereconcile/' + clientLists[0].value} />;
                  else return <Redirect to={'/contents/' + clientLists[0].value} />;
                }}
              />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      );
    } else {
      return <div className="error-message">Please reach admin to verify your client details in cms and bouncer!</div>;
    }
  } else {
    return <div className="error-message">Loading...</div>;
  }
}

export default App;
