import * as React from 'react';
import '../../styles/header.css';
import { Header } from '@StixNext/mojito-ui';
import { useHistory } from 'react-router-dom';
import { getAuthorizedClient } from '../../utils/AuthorizedFunction';
import keycloak from '../../utils/keycloak';

interface IHeaderProps {
  roles: string[];
  clientsDetails: [{ value: string; label: string }];
}

function NavBar(props: IHeaderProps) {

  const { roles, } = props;
  const clientLists = getAuthorizedClient(roles, props?.clientsDetails);
  const [showValue,setShowValue] = React.useState<Boolean>();
  let history = useHistory();
  const urlPath = history.location.pathname.split('/');
  const clientId = props?.clientsDetails.find((client) => client.value === urlPath[urlPath.length - 1]);
  const [selectedClient, setSelectedClient] = React.useState(clientId?.value ?? clientLists[0].value);

  const onClickHandler = (url: string) => {
    history.push(url + selectedClient);
  };

  const onClientListClickHandler = (clientId: string) => {
    const prevClientId = selectedClient;
    setSelectedClient(clientId);
    const clientDetails = props?.clientsDetails.find((client) => client.value === clientId);
    window.localStorage.setItem('clientDetails', JSON.stringify(clientDetails));
    const baseUrl = history.location.pathname.replace(prevClientId, clientId);
    history.push(baseUrl);
  };

  const onClickUserLogout = () => {
    keycloak.logout();
  };
  React.useEffect(()=>{
  setShowValue(props?.clientsDetails?.filter((item)=>item?.value=="SSOLOUNGE")?true:false);
  },[])

  return (
    <Header brand="DCH CMS">
      <Header.Nav>
        <Header.NavItem className="type-selection" label="CMS">
          <Header.NavMenu>
            {roles.includes('DCH_SUPER_ADMIN') && <Header.NavMenuItem onClick={() => onClickHandler('/')}>Collections</Header.NavMenuItem>}
            <Header.NavMenuItem onClick={() => onClickHandler('/contents/')}>Contents</Header.NavMenuItem>
            {(roles.includes('DCH_SUPER_ADMIN') || roles.includes('DCH_ADMIN') || roles.includes('DCH_CS') || roles.includes('DCH_FINANCE')) &&
              showValue && (
                <>
                  <Header.NavMenuItem onClick={() => onClickHandler('/listRoom/')}>Watch Party</Header.NavMenuItem>
                  <Header.NavMenuItem onClick={() => onClickHandler('/GenerateOfferCode/')}>Offer Code Generation</Header.NavMenuItem>
                  <Header.NavMenuItem onClick={() => onClickHandler('/eventReminders/')}>EventReminders</Header.NavMenuItem>
                </>
              )}
          </Header.NavMenu>
        </Header.NavItem>
        {!roles.includes('DCH_CONTENT_ADMIN') && (
          <Header.NavItem className="report-selection" label="Sales">
            <Header.NavMenu>
             
                <>
                  <Header.NavMenuItem onClick={() => onClickHandler('/sales/')}>Sales</Header.NavMenuItem>
                  <Header.NavMenuItem onClick={() => onClickHandler('/offerCodes/')}>Offer Codes</Header.NavMenuItem>
                </>
            
              {(roles.includes('DCH_SUPER_ADMIN') || roles.includes('DCH_FINANCE')) && (
                <Header.NavMenuItem onClick={() => onClickHandler('/financereconcile/')}>Finance Reconcile</Header.NavMenuItem>
              )}
            </Header.NavMenu>
          </Header.NavItem>
        )}
        {(roles.includes('DCH_SUPER_ADMIN') ||
          roles.includes('DCH_ADMIN') ||
          roles.includes('DCH_FINANCE') ||
          roles.includes('DCH_CONTENT_ADMIN')) && (
          <Header.NavItem className="report-selection" label="Reports">
            <Header.NavMenu>
              {roles.includes('DCH_SUPER_ADMIN') && (
                <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/salesByPatronReport/')}>Sales By Patron</Header.NavMenuItem>
              )}
              {(roles.includes('DCH_SUPER_ADMIN') || roles.includes('DCH_ADMIN') || roles.includes('DCH_CONTENT_ADMIN')) && (
                <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/salesSummaryReport/')}>Sales Summary</Header.NavMenuItem>
              )}
              {(roles.includes('DCH_SUPER_ADMIN') || roles.includes('DCH_ADMIN')) && (
                <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/salesByPriceReport/')}>Sales By Price</Header.NavMenuItem>
              )}
              {(roles.includes('DCH_SUPER_ADMIN') || roles.includes('DCH_FINANCE') || roles.includes('DCH_CONTENT_ADMIN')) && (
                <>
                  <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/settlementReport/')}>Settlement</Header.NavMenuItem>
                  {(roles.includes('DCH_SUPER_ADMIN') || roles.includes('DCH_FINANCE')) && (
                    <>
                      <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/accountsReport/')}>Accounts</Header.NavMenuItem>
                      <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/financialAnalysisReport/')}>
                        Financial Analysis
                      </Header.NavMenuItem>
                      <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/inventoryReport/')}>Inventory Report</Header.NavMenuItem>
                      <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/returnsReport/')}>Returns Report</Header.NavMenuItem>
                      <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/journalcsv/')}>Journal csv</Header.NavMenuItem>
                      <Header.NavMenuItem onClick={() => onClickHandler('/reportlayout/invoicecsv/')}>Invoice csv</Header.NavMenuItem>
                    </>
                  )}
                </>
              )}
            </Header.NavMenu>
          </Header.NavItem>
        )}
      </Header.Nav>
      <Header.Right>
        <Header.NavItem className="tenant-selection" label={selectedClient}>
          <Header.NavMenu>
            {clientLists.map((clientList) => {
              return <Header.NavMenuItem onClick={() => onClientListClickHandler(clientList.value)}>{clientList.label}</Header.NavMenuItem>;
            })}
          </Header.NavMenu>
        </Header.NavItem>
        <Header.NavItem className="user-selection" label="Hi, User">
          <Header.NavMenu>
            <Header.NavMenuItem onClick={() => onClickUserLogout()}>Logout</Header.NavMenuItem>
          </Header.NavMenu>
        </Header.NavItem>
      </Header.Right>
    </Header>
  );
}

export default NavBar;
