import React, { useEffect, useState } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Button, TextField } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Snackbars, { EAlertType } from '../common/Snackbars';
import DeleteAction from '../common/DeleteAction';
import { auditLogger, EAUDIT_COLLEACTION, EAUDIT_TYPE } from '../../utils/Utils';

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_REPORT_URL = process.env.REACT_APP_REPORT_URL;

const RoomListing = () => {
  const axiosConfig = {
    headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
  };
  const { clientId } = useParams() as { clientId: string | number };
  const history = useHistory();
  const url = `${API_URL}/watch-party/`;

  const [roomLists, setRoomLists] = React.useState<Record<string, any>[]>([]);
  const [filteredRoomLists, setFilteredRoomLists] = React.useState<Record<string, any>[]>([]);
  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });
  const [programmeDetails, setProgrammeDetails] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/watch-party?tenant=${clientId}`)
      .then((response) => {
        setRoomLists(response.data.data);
        setFilteredRoomLists(response.data.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
    axios
      .get(`${API_URL}/contents?collection=Programes&tenant=${clientId}`, axiosConfig)
      .then((response) => {
        if (response.data.data.length > 0) {
          setProgrammeDetails(response.data.data);
        } else {
          setProgrammeDetails([]);
        }
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, [alertOptions, clientId]);

  const onClickHandler = (url: string) => {
    history.push(url + `${clientId}/` + '');
  };

  const onEditClickHandler = (roomId: string) => {
    history.push('/createRoom/' + clientId + `/${roomId}`);
  };

  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };

  const invalidateGuests = (deletedData) => {
    const programe = programmeDetails.find((programe) => programe.id === deletedData.videoId);
    const data = {
      client: clientId,
      invitees: deletedData.guest,
      roomInfo: {
        roomid: deletedData.id,
        roomName: deletedData.name,
        sessionid: programe?.id,
        sessionTitle: programe?.title,
        session_starttime: programe?.startTime,
      },
    };
    axios
      .post(`${REACT_APP_REPORT_URL}/watchparty/invalidateVipInvitation`, data)
      .then((response) => {
        setAlertOptions({ isOpen: true, message: 'Invitation invalidated successfully', type: EAlertType.SUCCESS });
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to invalidate Invitation! Try again', type: EAlertType.ERROR });
      });
  };

  const onDeleteClickHandler = (url: string, deletedData: Record<string, any>) => {
    axios
      .delete(url)
      .then((response) => {
        invalidateGuests(deletedData);
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
    // AUDIT LOG
    auditLogger(EAUDIT_TYPE.DELETE, EAUDIT_COLLEACTION.WATCH_PARTY, JSON.stringify(deletedData), JSON.stringify({}));
  };

  const onSearchChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchValue = event.target.value;
    if (searchValue === '') {
      setFilteredRoomLists(roomLists);
    } else {
      const filteredroomLists = roomLists.filter((roomLists) => {
        const obj = Object.keys(roomLists).some((key) => {
          if (roomLists[key].toString().toLowerCase().includes(searchValue.toLowerCase())) {
            return roomLists;
          }
        });
        if (obj) return true;
      });
      setFilteredRoomLists(filteredroomLists);
    }
  };

  return (
    <div className="container-wrapper">
      <div className="container-title search-container">
        <TextField
          id="outlined-basic"
          style={{ width: '400px' }}
          label="Search"
          variant="outlined"
          name="search"
          onChange={(e) => {
            onSearchChangeHandler(e);
          }}
        />
        <div style={{ paddingLeft: '20px' }}>
          <Button variant="contained" color="inherit" onClick={() => onClickHandler('/createRoom/')}>
            Add
          </Button>
        </div>
      </div>
      <div className="watch-party-form-container">
        {alertOptions.isOpen && (
          <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
        )}
        <div className="room-listing-details">
          <div className="room-listing-form-element">
            <label>Private Room Name</label>
          </div>
          <div className="room-listing-form-element">
            <label>Private Room ID</label>
          </div>
          <div className="room-listing-form-element">
            <label>Product</label>
          </div>
          <div className="room-listing-form-element"></div>
        </div>
        <div className="container-underline"></div>

        {filteredRoomLists.length > 0 &&
          filteredRoomLists.map((row, index) => (
            <div key={index}>
              <div className="room-listing-details room-listing-details-item">
                <div className="room-listing-form-element">
                  <label>{row.name}</label>
                </div>
                <div className="room-listing-form-element">
                  <label>{row.id}</label>
                </div>
                <div className="room-listing-form-element">
                  <label>{row.product}</label>
                </div>
                <div className="room-listing-form-element action-button">
                  <EditOutlinedIcon style={{ marginRight: '20px' }} color="secondary" onClick={() => onEditClickHandler(row._id)} />
                  <DeleteAction onDeleteClickHandler={onDeleteClickHandler} url={url + row._id} deletedData={row} />
                </div>
              </div>
              <div className="container-underline"></div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RoomListing;
